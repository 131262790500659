var msg = new Array();
msg["ERR_PRIVACY"] = "You must accept the conditions to continue.";
msg["ERR_MISSINGFIELDS"] = "Fill in all mandatory fields.";
msg["ERR_FILLEMAILFIELD"] = "Fill in the email field.";
msg["ERR_USEREXISTS"] = "User exists.";
msg["ERR_INVALIDMAIL"] = "Invalid email address.";
msg["ERR_CLIENTMANDATORYFIELDS"] = "Fill in all mandatory fields";
msg["ERR_NOSIZESELECTED"] = "You have to select a size";
msg["ERR_LOGINREQUIRED"] = "Login required";
msg["ERR_LOGININVALID"] = "Invalid email or password";
msg["ERR_CARRIERMISSING"] = "You have to select a dispatch type first.";
msg["ERR_CARRIER_COST_MISSING"] =
	"No fee available for selected country carrier and order weight.";
msg["MSG_DEFERRED_DISPATCH_COMPUTATION"] =
	"Only order booking mode will be enabled and dispatch fees will be sent you by email.";
msg["MSG_ADDEDTOWISHLIST"] = "Product added to your wishlist";
msg["MSG_ADDEDTOCART"] = "Products added to your cart";
msg["MSG_WAIT"] = "Wait please...";
msg["MSG_SUBJECT_PRODUCTSHARE"] = " shared something with you";
msg["MSG_SENDER_PRODUCTSHARE"] = "Your name";
msg["MSG_MESSAGE_PRODUCTSHARE"] = "Message";
msg["MSG_MAILSENT"] = "E-Mails sent";
msg["MSG_MIXED_INTANGIBLE"] =
	"This product cannot be bought together with products you already have in your shopping cart.";
msg["MSG_MIXED_CART"] =
	"This product cannot be bought together with products you already have in your shopping cart because they are in different warehouses.";
msg["MSG_INSUFFICIENT_STOCK"] =
	"Sorry, at the moment we don't have enough product availability.";
msg["MSG_APPLIEDVATCHANGED"] =
	"After billing data upgrade, VAT applied to your order changed. Check the new amount once page gets reloaded.";
msg["MSG_GENERIC_ERROR"] = "Sorry, an error occurred.";
msg["MSG_COUPON_ERROR"] =
	"The coupon code inserted is not valid or has been already burned.";
msg["MSG_COUPON_VALID"] =
	"The coupon code inserted has been accepted successfully.";
msg["MSG_RECOVEREMAILSENT"] =
	"Check your email and enter the token in the form below.";
msg["MSG_RECOVEREMAILSENT_ERROR"] =
	"Warning: you are not registered with this e-mail address or you are already resetting your password.";
msg["MSG_EXTRAUELIMIT"] =
	"You cannot check-out orders with a taxable less than 2000.00 &euro;";
msg["MSG_SUBSCRIPTION_FAILURE"] =
	"Make sure you do not have any other active subscription.";
msg["MSG_RULE_REJECT"] =
	"Product cannot be added to cart because of rule :rule";
msg["LABEL_ARTICLES"] = "articles";
msg["LABEL_WARNING"] = "Warning";
msg["LABEL_NOTAPPLICABLE"] = "Not applicable";
msg["LABEL_ALREADYREGISTEREDUSERES"] = "Already registered users";
msg["LABEL_SENTINVITES"] = "Sent invites";
msg["LABEL_NOTSENTINVITES"] = "Unsent invites";
msg["LABEL_INVITES_ALREADY_SENT"] = "Users already invited";
msg["LABEL_SUBMIT"] = "Submit";
msg["LABEL_SELECTALL"] = "Select all";
msg["MSG_MESSAGE_SENT"] = "Message sent successfully!";
msg["MSG_RETURN_REQUEST_SUCCESS"] = "Return Request created successfully";
msg["MSG_SAVE_SUCCESS"] = "Save successful";
msg["MSG_SUBSCRIBED"] = "Iscritto con successo";
msg["MSG_PROFILECONSENT_SAVED"] = "Your contact preferences have been updated";
msg["MSG_PAYMENT_PENDING"] = "Payment pending validation";
msg["MSG_SUBSCRIPTION_NO_RENEWABLE"] =
	"Too many days have passed so the subscription is no longer renewable";
msg["wrongpassword"] = "The password you entered is incorrect";
msg["passwordnotcomplex"] = "Password is not strong enough";
msg["MSG_PASSWORD_OK"] = "Password changed correctly";
msg["MSG_TOO_MANY_REQUESTS"] = "Warning! You clicked too many times";
msg["MSG_PASSWORD_NOT_EQUAL"] =
	"New password and confirmation password do not match";
msg["passwordnotcomplex"] = "The password is not complex enough: it must contain an uppercase letter, a number, and at least 8 characters"

var portal_livestock_available = "AVAILABLE";
var portal_livestock_soldout = "SOLD OUT";

var activity_online_shop = "online shop";
var activity_shop = "shop";
var activity_private_sales = "private sales";
var activity_outlet = "outlet";
var activity_online_outlet = "online outlet";
var activity_store_chain = "chain of stores";
var activity_purchasing_group = "purchasing group";
var activity_broker = "broker/agent";
var activity_ebay_amazon = "ebay/amazon store";
var activity_other = "other";

var general = "General";
var general_personaldata = "Personal data";
var general_olduser = "Registered user";
var general_newuser = "New user";
var general_username = "Username";
var general_password = "Password";
var general_contacts = "Contacts";
var general_phone = "Phone Nr";
var general_skype = "Skype";
var general_website = "WebSite";
var general_fax = "Fax";
var general_firstname = "First name";
var general_lastname = "Last name";
var general_company = "Company";
var general_birth = "Date of birth";
var general_code = "Code";
var general_model = "Model";
var general_quantity = "Quantity";
var general_availability = "Availability";
var general_price = "Price";
var general_warning = "Warning";
var general_dispatchto = "Company name/Adressee";
var general_careof = "C/O";
var general_vat = "VAT";
var general_address = "Address";
var general_addresstype = "Address type";
var general_addressnumber = "Number";
var general_city = "City";
var general_prov = "Prov";
var general_zip = "ZIP";
var general_mobile = "Mobile";
var general_notes = "Notes";
var general_orders = "Orders";
var general_fgfdiscounts = "Friends and discounts";
var general_myprofile = "My Profile";
var general_pieces = "pc";
var general_size_table = "Size Table";
var general_details = "Details";
var general_invoice = "Invoice";
var general_creditnote = "Credit Note";
var general_cart = "Shopping Cart";
var general_country = "Country";
var general_carrier = "Carrier";
var general_dispatch_address = "Dispatch Address";
var general_invoice_address = "Invoice Address";
var general_receive_newsletter = "Receive newsletter";
var general_copydispatchfrombilldata = "Copy from billing data";
var general_instructions = "Instructions";
var general_add = "Add";
var general_status = "Status";
var general_toomanyfilters =
	"Hai raggiunto il numero massimo di filtri permessi.";

var help_addresstype = "Via, Piazza, Corso, ...";
var help_address = "Street name";
var help_dispatchmobile = "This number will be used by the carrier.";
var help_ordercfpiva =
	"A blank value will automatically add a 20% VAT to the order value.";
var help_cf = "Insert your SSN";

var buttons_save = "Save";
var buttons_changepw = "Change password";
var buttons_close = "Close";
var buttons_continue = "Continue";
var buttons_confirm = "Confirm";
var buttons_cancel = "Cancel";
var buttons_invite = "Invita";
var buttons_continueshopping = "Continue with shopping";
var buttons_gocheckout = "Go checkout";

var messages_product_soldout = "Product sold out.";
var messages_noselection = "No selection";
var messages_invaliddata = "Invalid data.";
var messages_recoverpassword = "Recover password";
var messages_recoverpassword_info =
	"Insert the email you subscribed with<br/>You will receive an email with the istructions to get a recover your password";
var messages_invitefriends = "Invite your friends";
var messages_confirmpassword = "Confirm password";
var messages_sendingmail = "Sending mail...";
var messages_mailsent = "Email sent.";
var messages_password = "Password";
var messages_confirmpassword = "Password confirm";
var messages_requiredfield = "Required field";
var messages_digitsonly = "Enter digits only";
var messages_minlength = "Please enter at least {0} characters";

var subscribe_personaldata = "Personal data";
var subscribe_privacyintro =
	"You have to accept the following condition to proceed with the subscription.";
var subscribe_privacydisclaimer =
	"You have to accept the privacy displaimer to proceed with the subscription.";
var subscribe_privacy1 = "I accept the privacy disclaimer";
var subscribe_privacy2 =
	"I accept that my personal data can be used by other firms for marketing campaigns (optional)";

var order_status = "Status";
var order_date = "Date";
var order_dispatchdate = "Dispatched";

var returnedgoods_requests = "Returned goods request";
var returnedgoods_motivations = "Returned goods motivation";
var returnedgoods_requestcode = "Returned goods request code";
var returnedgoods_requestdate = "Request Date";
var returnedgoods_motivations_1 = "I don't like the product";
var returnedgoods_motivations_2 = "The product is too big/small";
var returnedgoods_motivations_3 = "The product is damaged";
var returnedgoods_motivations_4 =
	"The product doesn't correspond to the ordered good";
var returnedgoods_motivations_5 = "The product is not in the packet";
var returnedgoods_motivations_other = "Other";
