$(function() {
  const url = new URL(window.location.href)
  
  loadMerchantPage()

  function loadMerchantPage(isMenuOpen = false) {
    if(isMenuOpen) {
      $('.filters-container-mobile').addClass('open')
    }
    $('.merchant-filters-dropdown a').on('click', function(event) {
      event.preventDefault()
      const filterKey = $(this).data('filter-key')
      const filterValue = $(this).data('filter-value')
  
      const currentParams = url.searchParams.get(filterKey) ? url.searchParams.get(filterKey).split(',') : []
      if(currentParams.includes(filterValue)) {
        if(currentParams.length > 1) {
          url.searchParams.set(filterKey, currentParams.filter(item => item !== filterValue).join(','))
        }
        else {
          url.searchParams.delete(filterKey)
        }
      }
      else if(currentParams.length > 0){
        url.searchParams.set(filterKey, [filterValue, ...currentParams].join(','))
      }
      else {
        url.searchParams.set(filterKey, filterValue)
      }
      window.history.pushState(null, "", url.toString())
      const isOpen = $('.filters-container-mobile').hasClass('open')
      $('#merchants-container')
        .addClass('merchant-loading')
        // .load(url.toString() + " #merchants-container>*", function(){
        //   loadMerchantPage(isOpen);
        //   $('#merchants-container').removeClass('merchant-loading')
        //   $('#merchant-main-banner').css('display','none')
        //   setTimeout(() => $('#merchant-main-banner').fadeIn(200), 200)
        // })


        $.ajax({
          url: url.toString(),
          method: 'GET',
          dataType: 'html',
          success: function(data) {
            const $data = $('<div>').html(data)
            $('#merchants-container').html($data.find('#merchants-container').html())
            loadMerchantPage(isOpen)
            $('#merchants-container').removeClass('merchant-loading')
            $('#merchant-main-banner').css('display','none')
            setTimeout(() => $('#merchant-main-banner').fadeIn(200), 200)
          },
          error: function(data) {
            $('#merchants-container').removeClass('merchant-loading')
            $('#merchants-container').addClass('merchant-error')
            $('body').css('overflow','hidden')
            showMessageBox('danger', msg['MSG_GENERIC_ERROR'], false, ()=>{
              window.location.reload()
            });
          }
        })

    })

    $('#filters-button-mobile').on('click', function(){
      $(this).siblings('.filters-container-mobile').toggleClass('open')
    })
  }


  
})