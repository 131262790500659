$(document).ready(function () {
    'use strict';

    function submitUserUpdateForm(form, event) {
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            beforeSend: function (data) {
                form.find(':input').prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    //TODO customize message
                    showMessageBox('success', 'Save successful');
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                form.find(':input').prop('disabled', false);
                if (form.data('nexturl')) {
                    window.location.href = form.data('nexturl');
                }
                else {
                    window.location.reload();
                }
            }
        });
    }

    $('#user-update-data-form #type-tags input:checkbox').on('click', function () {
        validateTags('#user-update-data-form #type-tags');
    });
    $('#user-update-data-form #gender-tags input:checkbox').on('click', function () {
        validateTags('#user-update-data-form #gender-tags');
    });
    $('#user-update-data-form #category-tags input:checkbox').on('click', function () {
        validateTags('#user-update-data-form #category-tags');
    });
    $('#user-update-data-form #storecount-tags input:checkbox').on('click', function () {
        validateTags('#user-update-data-form #storecount-tags');
    });

    $('#user-update-data-form').validate({
        rules: {
            firstname: 'required',
            lastname: 'required',
            company: 'required',
            notes: 'required',
            cel_prefix: 'required',
            cel: {
                required: true,
                digits: true,
                minlength: 5
            }
        },
        
        showErrors: function (errorMap, errorList) {
            this.defaultShowErrors();
            // submitHandler is not called if there are error present. we need to
            // check for errors in tags somewhere else.
            validateTags('#type-tags');
            validateTags('#gender-tags');
            validateTags('#category-tags');
            validateTags('#storecount-tags');
        },
        submitHandler: function (form) {
            if (validateTags('#type-tags') && validateTags('#gender-tags') &&
                validateTags('#category-tags') && validateTags('#storecount-tags')) {
                    submitUserUpdateForm($(form), event);
            }
            else {
                showMessageBox('danger', messages_invaliddata);
            }
        }
    });

    $('#user-update-dispatch-form').validate({
        rules: {
            d_addressee: 'required',
            d_country_id: 'required',
            d_street: 'required',
            d_number: 'required',
            d_cap: 'required',
            d_city: 'required',
            d_prov: 'required',
            d_cel_prefix: 'required',
            d_cel: 'required'
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            submitUserUpdateForm($(form), event);
        }
    });

    $('#user-update-invoice-form').validate({
        rules: {
            i_addressee: 'required',
            cfpiva: 'required',
            i_country_id: 'required',
            i_street: 'required',
            i_number: 'required',
            i_cap: 'required',
            i_city: 'required',
            i_prov: 'required'
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            submitUserUpdateForm($(form), event);
        }
    });

    $('#password-change-form').validate({
        rules: {
            current: 'required',
            password: 'required',
            password_confirm: {
                equalTo: '#password'
            }
        }
    });

    $('#password-change-form').submit(function (event) {
        event.preventDefault();
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test($("#password").val())) {
            showMessageBox('danger', msg['passwordnotcomplex']);
        } else if ($("#password").val() !== $("#password_confirm").val()) {
            showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
        } else {
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $(this).find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_OK']);
                    } else {
                        showMessageBox('danger', msg[data.errors.message]);
                    }
                },
                complete: function (data) {
                    $(this).find(':input').prop('disabled', false);
                }
            });
        }
    });


    // profile consent ajax
    $('#profile-consent-form').submit(function (event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents': []
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function (data) {

            },
            success: function (data) {

            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            }
        });
    });


});
