$(document).ready(function() {
	'use strict';
	var lowerCaseName = "username".toLowerCase();
	$(".stp3").hide();
	//recover password
	$('#password-recover-form').validate({
		submitHandler : function(form, event) {
			event.preventDefault();
			var email = $(form).find('#recoveremail').val();
			var password = $(form).find('#recoverpassword').val();

			$.ajax({
				type : $(form).attr('method'),
				url : $(form).attr('action'),
				data : {
					email : email,
					password : password
				},
				dataType : 'json',
				success : function(xdata) {
					if (xdata.success) {
						showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
						$(".step-login-text.second-step").removeClass("off-state");
						$(form).hide()
						$('#password-new-form-token').show()
						copyFormInputs('#password-recover-form', '#password-new-form-token', {
							'recoveremail': 'email'
						});
					} else {
						showMessageBox('danger', msg['MSG_RECOVEREMAILSENT_ERROR']);
					}
				},
				error: function(){
					showMessageBox('danger', msg['MSG_RECOVEREMAILSENT_ERROR']);
				},
				failed : function(){
					showMessageBox('danger', msg['MSG_RECOVEREMAILSENT_ERROR']);
				}
			});
		}
	});

	$('#password-new-form-token').validate({
		submitHandler: function(form, event) {
		event.preventDefault();
    var $form = $(form);

			$.ajax({
					type: $form.attr('method'),
					url: $form.attr('action'),
					data: $form.serialize(),
					dataType: 'json',
					beforeSend: function(data) {
							$form.find(':input').prop('disabled', true);
					},
					success: function(data) {
							if (data.success) {
								showMessageBox('success', msg['MSG_PASSWORD_OK']);
								
								$(".stp2").hide();
								$(".stp3").show();

								$('#inputs').hide()
								$('#password-changed-title').removeClass('hide')
								$('.step-login-text.third-step').removeClass('off-state')
								
								setTimeout(function() {
									window.location.href = $form.data('nexturl');
								},1000)
							} 
							else if (data.errors.message == 'login.user.passwordnotcomplexenought') {
								$('#password-new-form-token #password').siblings('.form-error').text(msg['passwordnotcomplex']).css('display', 'block')                    
							}
							else {
								showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
							}
					},
					failed: function(data) {
							showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
					},
					complete: function(data) {
							$form.find(':input').prop('disabled', false);
					}
			});
		}
	});

	function copyFormInputs(sourceForm, destForm, nameMap) {
		const source = $(sourceForm);
		const dest = $(destForm);

		for (const [sourceName, destName] of Object.entries(nameMap)) {
				const sourceInput = source.find(`[name=${sourceName}]`);
				const destInput = dest.find(`[name=${destName}]`);

				if (exists(sourceInput) && exists(destInput)) {
						destInput.val(sourceInput.val()).trigger('change');
				}
		}
	}

	function exists(selector) {
		try {
				return $(selector).length > 0;
		}
		catch {
				return false;
		}
	}

	$('#login-form').validate({
		rules : {
			username : 'required',
			password : 'required'
		}
	});

	/*$("#username").on('change keyup paste',function(){
	 $(this).val($(this).val().toLowerCase());
 })*/

  $('.login-toggle-mode').on('click', function(event) {
    event.preventDefault();
    var $this = $(this);
		if($(".eye:visible").length)
        $(".eye").hide();
    else
        $(".eye").show();
    $($this.attr('data-show')).slideDown();
    $($this.attr('data-hide')).slideUp();
  });
});
