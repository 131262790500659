$(function(){

  $('#cart-selector-container [type=submit]').on('click', function(event){
    event.preventDefault();
    event.stopImmediatePropagation();
    const $form = $(this).closest('form');
    $form.find('[name=merchantId]').val(this.dataset.merchantid);
    $(this).trigger('submit')
  })

  const selectedIndex = $('#cart-selector-container .selected').index()
  const container = $('#cart-selector-container')
  const selectors = $('#cart-selector-container .cart-selector')

  const totWidth = selectors.toArray().slice(0, selectedIndex-1)
    .map(item => item.offsetWidth)
    .reduce((a, b) => a + b, 0);

    container.scrollLeft(totWidth)
  
    $('#cart-selector-container').validate({
        submitHandler: function (form) {
            const $form = $(form);
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        window.location.href = $form.data('nexturl');
                    }
                    else {
                        alert('Cannot pay this order.');
                    }
                },
                error: function (data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            });
        }
    });
})